
<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Asset Preview
      </h3>
    </div>

    <div
      v-if="assetFull"
      class="modal-content px-8 py-4 bg-neutral-gray"
    >
      <div class="viewer-content text-gray-600">
        <ViewerContainer :asset="assetFull" />
      </div>
      <div class="content-main">
        <OverviewContainer
          :asset="assetFull"
          :show-description="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
const ViewerContainer = () => import('@components/viewer/ViewerContainer')

const OverviewContainer = () => import('@components/viewer/OverviewContainer')

export default {

  name: 'AssetPreview',

  components: {
    ViewerContainer,
    OverviewContainer
  },

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  computed: {
    assetFull () {
      return this.$store.getters['assets/getAssetById'](this.asset.id)
    }
  },

  mounted () {
    this.$store.dispatch('assets/loadAsset', { id: this.asset.id })
  }
}
</script>
